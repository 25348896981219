<template>
  <svg height="40" width="40" class="<%= local_assigns[:class] %>" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
      <path class="st0" d="M86.95,6.52H30.8V49.9l1.7,1.66l0.89,0.89c0.03,0.03,0.05,0.05,0.06,0.08l15.36,15.35l12.47,13.77H46.76
        L30.8,64.26v17.38H18.36V6.52h-5.33c-3.6,0-6.51,2.93-6.51,6.52v73.92c0,3.6,2.91,6.51,6.51,6.51h73.93c3.6,0,6.52-2.91,6.52-6.51
        V13.05C93.48,9.45,90.55,6.52,86.95,6.52z M44.07,55.24l-7.52-7.52l9.65-10.51h14.43L44.07,55.24z M74.39,81.64
        c-4,0-7.25-3.25-7.25-7.25c0-4,3.25-7.25,7.25-7.25s7.25,3.25,7.25,7.25C81.64,78.39,78.39,81.64,74.39,81.64z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
